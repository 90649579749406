@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif, Inter, Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F8F7F7;
  // overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.app-container {
  background-image : none;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

.menu-app{
  // display: block;
}

.menu-desktop{
  display: block;
}

// @media (max-width: 576px){
//   .app-container {
//     background-image: url('./assets/Rectangle-app.png');
//     height: 100vh;
//   }
// }

.text-left {
  text-align: initial !important;
}

.margin-bottom {
  margin-bottom: 4rem;
}

.btn-propig-secondary {
  width: 100%;
  border: none;
  border-radius: 25px;
  background: #FFD400;
  color: #002868;
  cursor: pointer;
  padding: 0.75rem;
}

.btn-propig-primary {
  color: white;
  border: none;
  border-radius: 25px;
  background: #002868;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.btn-propig-white {
  color: #002868;
  width: 100%;
  border: none;
  border-radius: 25px;
  background: white;
  cursor: pointer;
  padding: 0.75rem;
  border: solid 0.5px #FFD400;
}

.btn-propig-gray {
  color: #002868;
  border: none;
  border-radius: 25px;
  background: rgba(0, 40, 104, 0.15);
  cursor: pointer;
  padding: 0.75rem;
  border: solid 0.5px #D9D9D9;
}

.typography-lg {
  font-family: Inter;
  font-size: 35px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
}

@media (min-width: 992px) {
  .typography-lg {
    font-size: 48px;
    line-height: 45px;
  }
}

.typography-md {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.typography-sm {
  font-family: Inter;
  font-size: 8px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: right;
}

@media (max-width: 576px){
  .shared-components {
    position: absolute;
    width: 100%;
  }
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.divider {
  border-top: solid 2px #D9D9D9;
}

.mr-2{
  margin-right: 10px;
}

.ReactModalPortal > div {
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 100px;
  max-width: 350px;
}

.margin-dash {
  margin-left: 25%;
  margin-bottom: 50px;
  margin-top: 100px;

  max-width: 1200px;
}

.container-loading {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 9999999;
  background-color: #fdfdfd5b;

  display: flex;
  justify-content: center;
  align-items: center;
}

.TooltipContent {
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 15px;
  line-height: 1;
  color: black;
  background-color: #e1e7f0;
  box-shadow: 5px 2px 2px rgba(199, 203, 209, 0.20);
  user-select: none;
  animation-duration: 200ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.TooltipArrow {
  fill: #e1e7f0;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.TooltipContent[data-state='delayed-open'][data-side='top'] {
  animation-name: slideDownAndFade;
}
.TooltipContent[data-state='delayed-open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}
.TooltipContent[data-state='delayed-open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.TooltipContent[data-state='delayed-open'][data-side='left'] {
  animation-name: slideRightAndFade;
}