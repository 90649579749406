table {
  text-align: center;

  thead {
    height: 60px;
  }

  th{
    border-collapse: inherit;
    background: rgba(0, 40, 104, 0.15);
    padding: 5px;
    color: #737D8C;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    // min-width: 130px;
  }

  tr:first-child{
    th:first-child{
        border-radius: 20px 0px 0px 0px;
    }
    th:last-child{
        border-radius: 0px 20px 0px 0px;
    }
  }

  tr:nth-child(even){
    td{
        background: rgba(199, 203, 209, 0.10);
    }
  }

  td {
    padding: 15px 0px;
    color: #737D8C;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  th, td {
    font-family: Roboto !important;
    font-size: 13px;
    max-width: 150px;
  }
}

// .table-responsive{
//   max-width: 99%;
// }

.message-not-found {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto !important;
  color: #737D8C;
  height: 150px;
  text-align: center;
}

.loading-content {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}