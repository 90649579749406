
.login-logo-container {
    margin-bottom: 4rem;
}

@media (min-width: 992px) {
    .login-logo-container {
        margin-bottom: 3rem;
    }
}

@media (min-width: 992px) {
    .login-logo-img {
        width: 200px;
    }
}

.login-logo-img {
    width: 200px;
}

.login-label {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #2a2929;
    margin-left: 1.25rem;
}

@media (min-width: 992px) {
    .login-label {
        font-size: 16px;
        line-height: 18px;
    }
}

.loading-login{
    width: 85%;
    height: 78vh;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff3d;
}

.login-input {
    border: none;
    background: #F1F5F4;
    border-radius: 30px;
    padding: 1rem;
}

.need-help-span {
    color: #002868;
    background-color: #D9D9D980;
    font-family: Inter;
    font-size: 9px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: center;
    padding: 0.75rem 2rem;
    text-decoration: none;
}

@media (min-width: 992px) {
    .need-help-span {
        font-size: 11px;
    }
}

.need-help-container {
    bottom: 2rem;
    width: 100%;
    left: 0.75rem;
}

@media (min-width: 992px) {
    .need-help-container {
        bottom: 5rem;
    }
}

.error {
    color: red;
}

.loading-container {
    position: absolute;
}

.save-cnpj-col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.form-check-input:checked {
    background-color: #002868;
    border-color: #002868;
}

.banner-propig{
    background: radial-gradient(69.01% 69.01% at 50.00% 50.00%, #005988 0%, #002768 100%);
    width: 60%;
    height: 100vh;
    display: none;
    border-radius: 0px 70px 70px 0;
}

@media (min-width: 576px){
    .banner-propig {
        display: block!important;
    }
}

.login-card{
    padding: 58px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 20px -7px rgba(0, 0, 0, 0.25);
    margin: auto;
}

.login-container{
    background-color: #F1F1F1;
}

.login-banner-img{
    margin-top: 20%;
    width:100%;
}