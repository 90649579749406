.cover-img {
  background-image: url('../../assets/cover-mobile.png');
  background-repeat: no-repeat;
  height: 100vh;
  background-size: cover;
  position: absolute;
  width: 100%;
  top: 0;
}

@media (min-width: 992px) {
  .cover-img {
  }
}

.cover-opacity {
  background-image: url('../../assets/opacity.png');
  background-repeat: no-repeat;
  height: 100vh;
  z-index: 100;
  position: relative;
  width: 100%;
  background-size: contain;
}

.cover-content {
  position: absolute;
  top: 0;
  z-index: 200;
  width: 100%;
  padding: 2rem;
  height: 100vh;
}

@media (min-width: 992px) {
  .cover-content {
    padding: 5rem;
  }
}

.cover-button {
  position: absolute;
  bottom: 2rem;
  width: calc(100% - 2rem);
}

@media (min-width: 992px) {
  .cover-button {
    width: calc(100% - 5rem);
    text-align: start;
    bottom: 12rem;
  }
}

.cover-title {
  color: white;
  margin-top: 6rem;
}

@media (min-width: 992px) {
  .cover-logo-img {
      width: 80px;
  }
}